import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {AppMixins} from "@/mixins/AppMixins";

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(VueSweetalert2,options)
    .component(VueFeather.type, VueFeather)
    .component('v-select', vSelect)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .mixin( {...AppMixins})

    .mount('#app');