import configRoutes from "@/router/configRoutes";
import auth from "@/router/middlewares/auth";
import vegolaRoutes from "@/router/vegolaRoutes";

const routes =  [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      authRequired: false,
    },
  },
  {
    path: "/forgot-password",
    name: "Mot de pass oublié",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Mot de passe oublié",
      authRequired: false,
    },
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: () => import("../views/account/PasswordReset.vue"),
    meta: {
      title: "Réinitialisation du mot de passe",
      authRequired: false,
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      middleware: [
        auth,
      ],
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/crm/index.vue"),
  },
  {
    path: "/notifications",
    name: "notifications.index",
    meta: {
      middleware: [
        auth,
      ],
      title: "Notifications",
      authRequired: true,
    },
    component: () => import("../views/notifications/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Profile", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/profile/setting.vue")
  },

  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/*/',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },

  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      middleware: [
        auth,
      ],
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      middleware: [
        auth,
      ],
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      middleware: [
        auth,
      ],
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      middleware: [
        auth,
      ],
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  //staff
  {
    path: '/staff',
    name: 'staff.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Utilisateur", authRequired: true,
    },
    component: () => import('../views/configs/common/staff/index.vue')
  },

  {
    path: '/configs',
    name: 'configs.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Configurations", authRequired: true,
    },
    component: () => import('../views/configs/index.vue')
  },

  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: {
      title: "Page introuvable",
    },
    component: () => import('../views/auth/errors/404-basic.vue')
  }

];

export default [...routes,...configRoutes,...vegolaRoutes];