const routes = [
    {
        path: '/campaign-producers',
        name: 'producers.campaigns',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Producteur", authRequired: true,
        },
        component: () => import('../views/producers/index.vue')
    },
    {
        path: '/producer-supports',
        name: 'producer-supports.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Appui reçu", authRequired: true,
        },
        component: () => import('../views/producer-supports/index.vue')
    },
    {
        path: '/crops',
        name: 'crops.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Recolte", authRequired: true,
        },
        component: () => import('../views/crops/index.vue')
    },
    {
        path: '/outputs',
        name: 'outputs.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Rendements", authRequired: true,
        },
        component: () => import('../views/outputs/index.vue')
    },
    {
        path: '/contracts',
        name: 'contracts.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Contrats", authRequired: true,
        },
        component: () => import('../views/contracts/index.vue')
    },
    {
        path: '/bills',
        name: 'bills.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Factures", authRequired: true,
        },
        component: () => import('../views/bills/index.vue')
    },
    {
        path: '/areas',
        name: 'areas.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Superficies", authRequired: true,
        },
        component: () => import('../views/areas/index.vue')
    },
    {
        path: '/harvests',
        name: 'harvests.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Recoltes", authRequired: true,
        },
        component: () => import('../views/harvests/index.vue')
    },
    {
        path: '/forecasts',
        name: 'forecasts.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Prévisions", authRequired: true,
        },
        component: () => import('../views/forecasts/index.vue')
    },
];


export default routes;