//import auth from "@/router/middlewares/auth";

import auth from "@/router/middlewares/auth";

export default [
    //customs
    {
        path: '/departements',
        name: 'departements.index',
        meta: {
             middleware: [
        //auth,
      ],
            title: "Départements", authRequired: true,
        },
        component: () => import('../../views/configs/common/departements/index.vue')
    },
    {
        path: '/support-types',
        name: 'support-types.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 'support-types', authRequired: true,
        },
        component: () => import('../../views/configs/support-types/index.vue')
    },
    {
        path: '/support-categories',
        name: 'support-categories.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 'support-categories', authRequired: true,
        },
        component: () => import('../../views/configs/support-categories/index.vue')
    },
    {
        path: '/supports',
        name: 'supports.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 'supports', authRequired: true,
        },
        component: () => import('../../views/configs/supports/index.vue')
    },
    {
        path: '/departements',
        name: 'departements.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Départements", authRequired: true,
        },
        component: () => import('../../views/configs/common/departements/index.vue')
    },
    {
        path: '/communes',
        name: 'communes.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Communes", authRequired: true,
        },
        component: () => import('../../views/configs/common/communes/index.vue')
    },
    {
        path: '/villages',
        name: 'villages.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Villages", authRequired: true,
        },
        component: () => import('../../views/configs/common/villages/index.vue')
    },
    {
        path: '/arrondissements',
        name: 'arrondissements.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Arrondissement", authRequired: true,
        },
        component: () => import('../../views/configs/common/arrondissements/index.vue')
    },
    {
        path: '/exercices',
        name: 'school-periods.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Exercices", authRequired: true,
        },
       // component: () => import('../../views/configs/ghm/school-periods/index.vue')
    },
    {
        path: '/roles',
        name: 'roles.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/index.vue')
    },
    {
        path: '/roles/:id/edit',
        name: 'roles.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/create.vue')
    },
    {
        path: '/roles/create',
        name: 'roles.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/create.vue')
    },
    {
        path: '/agents',
        name: 'agents.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Producteurs", authRequired: true,
        },
        component: () => import('../../views/configs/agents/index.vue')
    },
    {
        path: '/agents/create',
        name: 'agents.create',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Nouveau", authRequired: true,
        },
        component: () => import('../../views/configs/agents/create.vue')
    },
    {
        path: '/agents/:id',
        name: 'agents.show',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Détails agent", authRequired: true,
        },
        component: () => import('../../views/configs/agents/show.vue')
    },
    {
        path: '/agents/:id/edit',
        name: 'agents.edit',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Producteur", authRequired: true,
        },
        component: () => import('../../views/configs/agents/create.vue')
    },
    {
        path: '/campaigns',
        name: 'campaigns.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-Campaign-management', authRequired: true,
        },
        component: () => import('../../views/configs/campaigns/index.vue')
    },
    {
        path: '/campaigns/:id',
        name: 'campaigns.show',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-campaign-management', authRequired: true,
        },
        component: () => import('../../views/configs/campaigns/show.vue')
    },
    {
        path: '/campaigns/producers/:id',
        name: 'campaigns.add.producers',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-campaign-management', authRequired: true,
        },
        component: () => import('../../views/configs/campaigns/partials/AddProducers.vue')
    },
    {
        path: '/campaigns/collection-points/:id',
        name: 'campaigns.add.collection.points',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-campaign-management', authRequired: true,
        },
        component: () => import('../../views/configs/campaigns/partials/AddCollectionPoints.vue')
    },
    {
        path: '/users',
        name: 'users.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-User', authRequired: true,
        },
        component: () => import('../../views/configs/User/index.vue')
    },
    {
        path: '/collection-points',
        name: 'collection-points.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-Management-collection-points', authRequired: true,
        },
        component: () => import('../../views/configs/collection-points/index.vue')
    },
    /*{
        path: '/collection-points/:id',
        name: 'collection-points.show',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-Management-collection-points', authRequired: true,
        },
        component: () => import('../../views/configs/collection-points/show.vue')
    },*/
    {
        path: '/calibers',
        name: 'calibers.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-Caliber-management', authRequired: true,
        },
        component: () => import('../../views/configs/calibers/index.vue')
    },
    {
        path: '/inputs',
        name: 'inputs.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-inputs', authRequired: true,
        },
        component: () => import('../../views/configs/inputs/index.vue')
    },

]